import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { isLoggedIn } from "./Auth";

const PrivateRoute = () => {
  const isAuthenticated = isLoggedIn();
  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;

import { configureStore } from "@reduxjs/toolkit";
import styleReducer from "./reducers/preference/styleSlice";
import roomReducer from "./reducers/preference/roomSlice";
import colorReducer from "./reducers/preference/colorSlice";
import budgetReducer from "./reducers/preference/budgetSlice";
import cartSliceReducer from "./reducers/cart/cartSlice";

export const store = configureStore({
  reducer: {
    style: styleReducer,
    room: roomReducer,
    color: colorReducer,
    budget: budgetReducer,
    allCart: cartSliceReducer,
  },
});

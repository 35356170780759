import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedColors: [],
};

const colorSlice = createSlice({
  name: "color",
  initialState,
  reducers: {
    addSelectedColor: (state, action) => {
      const color = action.payload;
      const existingColorIndex = state.selectedColors.findIndex(
        (selectedColor) => selectedColor.id === color.id
      );

      if (existingColorIndex === -1) {
        state.selectedColors.push(color);
      }
    },
    removeSelectedColor: (state, action) => {
      const colorId = action.payload;
      state.selectedColors = state.selectedColors.filter(
        (color) => color.id !== colorId
      );
    },
    clearSelectedColors: (state) => {
      state.selectedColors = [];
    },
  },
});

export const { addSelectedColor, removeSelectedColor, clearSelectedColors } =
  colorSlice.actions;

export default colorSlice.reducer;

import React from "react";

const NotFound = () => {
  return (
    <div className="flex flex-col justify-center h-screen">
      <h1 className="text-black-900 text-center text-4xl font-black">
        Error 404
      </h1>
      <p className="text-black-900 text-center mt-5 text-lg font-semibold">
        Page not found!!
      </p>
    </div>
  );
};

export default NotFound;

import React, { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import RenderLazyComponent from "../utils/RenderLazyComponent";
import Error404 from "../pages/Error404";
import PrivateRoute from "../utils/PrivateRoute";
import NotFound from "../pages/NotFound";

// Lazy loaded components
const Home = lazy(() => import("../pages/Home"));
const Landing = lazy(() => import("../pages/Landing"));
const LogIn = lazy(() => import("../pages/auth/LogIn"));
const SignUp = lazy(() => import("../pages/auth/SignUp"));
const ForgotPassword = lazy(() => import("../pages/auth/ForgotPassword"));
const VerifyOtp = lazy(() => import("../pages/auth/VerifyOtp"));
const ResetPassword = lazy(() => import("../pages/auth/ResetPassword"));
const ChooseStyle = lazy(() => import("../pages/preference/ChooseStyle"));
const ChooseRoom = lazy(() => import("../pages/preference/ChooseRoom"));
const ChooseColor = lazy(() => import("../pages/preference/ChooseColor"));
const SelectBudget = lazy(() => import("../pages/preference/SelectBudget"));
const Products = lazy(() => import("../pages/Products"));
const ProductDetail = lazy(() => import("../pages/ProductDetail"));
// const MyCart = lazy(() => import("../pages/MyCart"));
const VerifyInvite = lazy(() => import("../pages/VerifyInvite"));

const Router = () => {
  return (
    <Routes>
      {/* <Route path="/" element={RenderLazyComponent(Landing)} /> */}
      <Route path="/" element={<NotFound />} />
      <Route path="*" element={<NotFound />} />
      {/* <Route path="/login" element={RenderLazyComponent(LogIn)} />
      <Route path="/signup" element={RenderLazyComponent(SignUp)} />
      <Route
        path="/forgotpassword"
        element={RenderLazyComponent(ForgotPassword)}
      />
      <Route path="/verifyotp" element={RenderLazyComponent(VerifyOtp)} />
      <Route
        path="/resetpassword"
        element={RenderLazyComponent(ResetPassword)}
      />
      <Route element={<PrivateRoute />}>
        <Route path="/home" element={RenderLazyComponent(Home)} />
        <Route path="/style" element={RenderLazyComponent(ChooseStyle)} />
        <Route path="/room" element={RenderLazyComponent(ChooseRoom)} />
        <Route path="/color" element={RenderLazyComponent(ChooseColor)} />
        <Route path="/budget" element={RenderLazyComponent(SelectBudget)} />
        <Route path="/products" element={RenderLazyComponent(Products)} />
      </Route>
      <Route
        path="/products/:id"
        element={RenderLazyComponent(ProductDetail)}
      />
      {/* <Route path="/mycart" element={RenderLazyComponent(MyCart)} /> */}
      {/* <Route path="/verifyinvite" element={RenderLazyComponent(VerifyInvite)} /> */}
      {/* <Route path="*" element={<Error404 />} /> */}
    </Routes>
  );
};

export default Router;

import { showSuccessToast } from "./Toast";

// Checks if the user is logged in or not
export const isLoggedIn = () => {
  let token = localStorage.getItem("token");
  if (token != null) return true;
  else return false;
};

// Set to localStorage
export const doLogin = (token, user) => {
  localStorage.setItem("token", JSON.stringify(token));
  localStorage.setItem("user", JSON.stringify(user));
};
// Remove from localStorage
export const doLogout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  localStorage.removeItem("user_preference_id");
  localStorage.removeItem("preference_combination_id");
  showSuccessToast("Logged out successfully");
};

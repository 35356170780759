import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedStyles: [],
};

const styleSlice = createSlice({
  name: "style",
  initialState,
  reducers: {
    addSelectedStyle: (state, action) => {
      const style = action.payload;
      const existingStyleIndex = state.selectedStyles.findIndex(
        (selectedStyle) => selectedStyle.id === style.id
      );

      if (existingStyleIndex === -1) {
        state.selectedStyles.push(style);
      }
    },

    removeSelectedStyle: (state, action) => {
      const styleId = action.payload;
      state.selectedStyles = state.selectedStyles.filter(
        (style) => style.id !== styleId
      );
    },
    clearSelectedStyles: (state) => {
      state.selectedStyles = [];
    },
  },
});

export const { addSelectedStyle, removeSelectedStyle, clearSelectedStyles } =
  styleSlice.actions;

export default styleSlice.reducer;

import React from "react";

const Loader = ({ w, h, h2, className }) => {
  const btnclass = className ? `${className}` : "border-grey-300";
  const loaderSize = `${w} ${h}`;
  const loaderLazyHeight = `${h2}`;
  return (
    <div className={`flex items-center justify-center ${loaderLazyHeight}`}>
      <div
        className={`animate-spin rounded-full ${loaderSize} border-t-2 ${btnclass}`}
      ></div>
    </div>
  );
};

export default Loader;

import React from "react";
import { isLoggedIn } from "../../utils/Auth";

const LazyLoader = ({ w, h, h2 }) => {
  const loaderSize = `${w} ${h}`;
  const loaderLazyHeight = `${h2}`;
  const userIsLoggedIn = isLoggedIn();
  return (
    <div
      className={`flex items-center justify-center ${loaderLazyHeight} ${
        userIsLoggedIn ? "bg-secondary-dark" : "bg-primary-light"
      }`}
    >
      <div
        className={`animate-spin rounded-full ${loaderSize} border-t-2 border-grey-300`}
      ></div>
    </div>
  );
};

export default LazyLoader;

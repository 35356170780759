import "./App.css";
import Router from "./router/Router";
import ScrollToTop from "./utils/ScrollToTop";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <>
      <ToastContainer position="top-center" pauseOnFocusLoss={false} />
      <ScrollToTop />
      <Router />
    </>
  );
}

export default App;

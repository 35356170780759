export const styleData = [
  {
    id: 1,
    src: require("./staticImage/gallaryimg01.png"),
    title: "Minimalistic Stil",
    value: "Minimalist Style",
  },
  {
    id: 2,
    src: require("./staticImage/gallaryimg02.png"),
    title: "Moderner Stil",
    value: "Modern Style",
  },
  {
    id: 3,
    src: require("./staticImage/gallaryimg03.png"),
    title: "Industrie Stil ",
    value: "Industrial Style",
  },
  {
    id: 4,
    src: require("./staticImage/gallaryimg04.png"),
    title: "Italienischer Stil",
    value: "Italian Style",
  },
  {
    id: 5,
    src: require("./staticImage/gallaryimg05.png"),
    title: "Skandinavischer Stil",
    value: "Scandinavian Style",
  },
  {
    id: 6,
    src: require("./staticImage/gallaryimg06.png"),
    title: "Kolonial Stil ",
    value: "Colonial Style",
  },
  {
    id: 7,
    src: require("./staticImage/gallaryimg07.png"),
    title: "Vintage Stil",
    value: "Vintage Style",
  },
  {
    id: 8,
    src: require("./staticImage/gallaryimg08.png"),
    title: "Inspiration",
    value: "Inspiration",
  },
  // Add more images as needed
];

export const roomData = [
  {
    id: 1,
    src: require("./staticImage/ChooseRoomImg01.png"),
    title: "Living Room",
  },
  {
    id: 2,
    src: require("./staticImage/ChooseRoomImg02.png"),
    title: "Kitchen",
  },
  {
    id: 3,
    src: require("./staticImage/ChooseRoomImg03.png"),
    title: "Dining Room",
  },
  {
    id: 4,
    src: require("./staticImage/ChooseRoomImg04.png"),
    title: "Bedroom",
  },
  {
    id: 5,
    src: require("./staticImage/ChooseRoomImg05.png"),
    title: "Dressing Room",
  },
  {
    id: 6,
    src: require("./staticImage/ChooseRoomImg06.png"),
    title: "Bathroom",
  },
  {
    id: 7,
    src: require("./staticImage/Office.png"),
    title: "Office",
  },
  {
    id: 8,
    src: require("./staticImage/Nursery.png"),
    title: "Nursery",
  },
];

export const colorData = [
  {
    id: 1,
    src: require("./staticImage/ChooseColorimg01.png"),
    title: "Black Interior",
  },
  {
    id: 2,
    src: require("./staticImage/ChooseColorimg02.png"),
    title: "White Interior",
  },
  {
    id: 3,
    src: require("./staticImage/ChooseColorimg03.png"),
    title: "Blue Interior",
  },
  {
    id: 4,
    src: require("./staticImage/ChooseColorimg04.png"),
    title: "Yellow Interior",
  },
  {
    id: 5,
    src: require("./staticImage/ChooseColorimg05.png"),
    title: "Red Interior",
  },
  {
    id: 6,
    src: require("./staticImage/ChooseColorimg06.png"),
    title: "Green Interior",
  },
  {
    id: 7,
    src: require("./staticImage/ChooseColorimg07.png"),
    title: "Violette Interior",
  },
  {
    id: 8,
    src: require("./staticImage/ChooseColorimg08.png"),
    title: "Inspiration",
  },
];

export const budgetData = [
  { id: 1, price: "1000-2500" },
  { id: 2, price: "2500-4500" },
  { id: 3, price: "4500-6500" },
  { id: 4, price: "6500-8500" },
];

export const ProductImage = [
  {
    id: 1,
    src: require("./staticImage/ProductImg01.png"),
    title: "Clorium Suite",
    price: "6580.00",
    quantity: 1,
  },
  {
    id: 2,
    src: require("./staticImage/ProductImg02.png"),
    title: "Dark Suite",
    price: "6580.00",
    quantity: 1,
  },
  {
    id: 3,
    src: require("./staticImage/ProductImg03.png"),
    title: "Open Suite",
    price: "6580.00",
    quantity: 1,
  },
  {
    id: 4,
    src: require("./staticImage/ProductImg04.png"),
    title: "Cosy Room",
    price: "6580.00",
    quantity: 1,
  },
  {
    id: 5,
    src: require("./staticImage/ProductImg01.png"),
    title: "Clorium Suite",
    price: "6580.00",
    quantity: 1,
  },
  {
    id: 6,
    src: require("./staticImage/ProductImg02.png"),
    title: "Dark Suite",
    price: "6580.00",
    quantity: 1,
  },
  {
    id: 7,
    src: require("./staticImage/ProductImg03.png"),
    title: "Open Suite",
    price: "6580.00",
    quantity: 1,
  },
  {
    id: 8,
    src: require("./staticImage/ProductImg04.png"),
    title: "Cosy Room",
    price: "6580.00",
    quantity: 1,
  },

  // Add more images as needed
];

export const SortData = [
  { id: 1, type: "Popular" },
  { id: 2, type: "Price" },
  { id: 3, type: "Alphabet" },
];

export const DecorPillows = [
  {
    id: 1,
    src: require("./staticImage/Room01.png"),
    title: "Linen & Seam",
    price: "€65.00",
  },
  {
    id: 2,
    src: require("./staticImage/Room02.png"),
    title: "Fiddle + Bloom",
    price: "€80.00",
  },
  {
    id: 3,
    src: require("./staticImage/Room03.png"),
    title: "Onyx Rowe",
    price: "€22.00",
  },
  {
    id: 4,
    src: require("./staticImage/Room04.png"),
    title: "Onyx Rowe",
    price: "€20.00",
  },

  // Add more images as needed
];

export const Lightining = [
  {
    id: 1,
    src: require("./staticImage/Room05.png"),
    title: "Clorium Suite",
    price: "€6580.00",
  },
  {
    id: 2,
    src: require("./staticImage/Room06.png"),
    title: "Dark Suite",
    price: "€6580.00",
  },

  // Add more images as needed
];

export const ProductDetailData = [
  {
    id: 1,
    src: require("./staticImage/RoomBanner.png"),
  },
];

export const AddCartData = [
  {
    id: 1,
    title: "1. Choose Cultery",
    src: require("./staticImage/Cultery.png"),
  },
  {
    id: 2,
    title: "2. Choose Wallpaint",
    src: require("./staticImage/Wallpaint.png"),
  },
  {
    id: 3,
    title: "3. Choose Wall textile",
    src: require("./staticImage/Wall_Textile.png"),
  },
];

const Image = {
  Logo: require("./image/Logo.png"),
  Home: require("./image/Home.png"),
  Furniture: require("./image/Furniture.png"),
  Chair: require("./image/Chair.png"),
  Sofa: require("./image/Sofa.png"),
  Furniture2: require("./image/Furniture2.png"),
  Arrow1: require("./image/Arrow1.png"),
  Arrow2: require("./image/Arrow2.png"),
  Arrow3: require("./image/Arrow3.png"),
  Arrow4: require("./image/Arrow4.png"),
  Arrow5: require("./image/Arrow5.png"),
  Arrow6: require("./image/Arrow6.png"),
  Menu: require("./icon/Menu.png"),
  Login: require("./image/Login.png"),
  VerticalLogo: require("./image/VerticalLogo.png"),
  DownArrow: require("./icon/Arrow.png"),
  PurplebgChair: require("./image/PurplebgChair.png"),
  Visa: require("./icon/01.png"),
  Paypal: require("./icon/02.png"),
  Alipay: require("./icon/03.png"),
  Unionpay: require("./icon/04.png"),
  Klarna: require("./icon/05.png"),
  Jcb: require("./icon/06.png"),
  AmericanExpress: require("./icon/07.png"),
  Discover: require("./icon/08.png"),
  DinersClub: require("./icon/09.png"),
  GreaterThan: require("./icon/greaterThan.png"),
  HomePageImg01: require("./image/homepageimg01.png"),
  Blurbg: require("./image/Blurbg.png"),
  HomePageImg02: require("./image/homepageimg02.png"),
  TabImg01: require("./image/Tabimg01.png"),
  TabImg02: require("./image/Tabimg02.png"),
  FooterLogo: require("./image/newlogo.png"),
  LinkedIn: require("./icon/LinkedIn.png"),
  Twitter: require("./icon/Twitter.png"),
  Youtube: require("./icon/Youtube.png"),
  AuthBg: require("./image/loginbg.png"),
  EyeClosed: require("./icon/closeeye.png"),
  EyeOpen: require("./icon/openeye.png"),
  Email: require("./icon/email.png"),
  Google: require("./icon/google.png"),
  Apple: require("./icon/apple.png"),
  FaceBook: require("./icon/fb.png"),
  PaymentLogo01: require("./icon/paymentlogo1.png"),
  PaymentLogo02: require("./icon/paymentlogo2.png"),
  Minimistic: require("./image/Minimalist.png"),
  Minimistic2: require("./image/Minimalist2.png"),
  Moderner: require("./image/Moderner.png"),
  Industrie: require("./image/Industrie.png"),
  Minus: require("./icon/minusIcon.png"),
  Plus: require("./icon/plusIcon.png"),
  one: require("./image/1.png"),
  two: require("./image/2.png"),
  three: require("./image/3.png"),
  four: require("./image/4.png"),
  Blurbg: require("./image/Blurbg.png"),
  ProductNameDialogBubbleBg: require("./image/Product_name_dialog_bubble_bg.png"),
  ShopIcon2: require("./icon/shop2.png"),
  ChatIcon: require("./icon/chat.png"),
  PurpleBg: require("../assets/image/Bg1.png"),
};
export { Image };

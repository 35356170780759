import { Suspense } from "react";
import LazyLoader from "../components/common/LazyLoader";

// Helper function to render lazy components with Suspense
const RenderLazyComponent = (LazyComponent) => {
  return (
    <Suspense fallback={<LazyLoader w={"w-20"} h={"h-20"} h2={"h-screen"} />}>
      <LazyComponent />
    </Suspense>
  );
};

export default RenderLazyComponent;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedRoom: null,
};

const roomSlice = createSlice({
  name: "room",
  initialState,
  reducers: {
    setSelectedRoom: (state, action) => {
      state.selectedRoom = action.payload;
    },
    clearSelectedRoom: (state) => {
      state.selectedRoom = null;
    },
  },
});

export const { setSelectedRoom, clearSelectedRoom } = roomSlice.actions;

export default roomSlice.reducer;

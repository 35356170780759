import React from "react";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <div>
      <div className="text-black-900 text-center mt-20 text-4xl font-black">
        Error 404
      </div>
      <div className="text-black-900 text-center mt-5 text-lg font-semibold">
        Page not found
      </div>
      <Link to="/">
        <div className="text-nativeBlue text-center mt-5 text-lg font-normal cursor-pointer">
          Go Home
        </div>
      </Link>
    </div>
  );
};

export default PageNotFound;

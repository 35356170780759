import React, { Component } from "react";
import Button from "../components/common/Button";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  handleGoBack = () => {
    window.history.back(); // This will navigate back to the previous page.
  };

  static getDerivedStateFromError(error) {
    console.log("Error", error);
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Log the error to a logging service here
    console.error("Error:", error);
    console.error("Error Info:", errorInfo);
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      // Render a custom error UI or component here
      return (
        <div>
          <div className="text-black-900 text-center mt-20 text-4xl font-black">
            OOPS! Something went wrong
          </div>
          <div className="text-black-900 text-center mt-5 text-lg font-semibold">
            Please try again later
          </div>
          <Button
            onClick={this.handleGoBack}
            className="text-nativeBlue text-center mt-5 text-lg font-normal cursor-pointer"
            title="Go Back"
          />
        </div>
      );
    }

    // If no error occurred, render the children components
    return this.props.children;
  }
}

export default ErrorBoundary;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedBudget: null,
};

const budgetSlice = createSlice({
  name: "budget",
  initialState,
  reducers: {
    setSelectedBudget: (state, action) => {
      state.selectedBudget = action.payload;
    },
    clearSelectedBudget: (state) => {
      state.selectedBudget = null;
    },
  },
});

export const { setSelectedBudget, clearSelectedBudget } = budgetSlice.actions;

export default budgetSlice.reducer;

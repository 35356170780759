import React from "react";
import { BsCart2 } from "react-icons/bs";
import Loader from "./Loader";
import { Image as Icon } from "../../assets/index";
// import Google from "../../assets/google.png";

const Button = ({
  title,
  onClick,
  className,
  icon,
  loading,
  add,
  minus,
  incrementCounter,
  decrementCounter,
  style,
}) => {
  const buttonClass = className
    ? `${className}`
    : "lock flex rounded-full bg-white px-7 py-3 text-16 sm:text-20 font-medium customewhitebutton cursor-pointer";
  return (
    <div style={style} onClick={onClick} className={buttonClass}>
      {/* {icon2 && <img src={Google} alt="Email Icon" className="mr-2 px-1" />} */}
      {loading ? (
        <Loader
          w={"w-5"}
          h={"h-5"}
          h2={"h-7"}
          className="border-black mt-0 lg:mt-0 hover:border-white"
        />
      ) : (
        <>
          {icon && (
            <div className="pe-1">
              <BsCart2 size={25} />
            </div>
          )}
          {minus && (
            <button onClick={decrementCounter} className="pe-5">
              <img
                className="object-contain w-5"
                src={Icon.Minus}
                alt="Minus"
                loading="lazy"
              />
            </button>
          )}
          {title}
          {add && (
            <button onClick={incrementCounter} className="ps-5">
              <img
                className="object-contain w-5"
                src={Icon.Plus}
                alt="Plus"
                loading="lazy"
              />
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default Button;
